import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";

Chart.register(ArcElement, Tooltip, Legend);

const PieChart = (props) => {
    const { data, labels, tooltip } = props
    const chartData = {
        labels: labels,
        datasets: [
            {
                data: data,
                backgroundColor: ["rgba(75, 192, 192, 1)", "rgba(255, 99, 132, 1)"],
                hoverBackgroundColor: ["rgba(75, 192, 192, 1)", "rgba(255, 99, 132, 1)"],
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: { position: "bottom" },
            tooltip: {
                enabled: true,
                callbacks: {
                    title: (tooltipItems) => {
                        const index = tooltipItems[0].dataIndex;
                        return tooltip[index]
                    },
                },
            },
        },
    };

    return <Pie data={chartData} options={options} />;
};

export default PieChart;
