import React, { useEffect, useState } from 'react'

import * as Api from "../../../../Config/Apis"
import endPoints from "../../../../Config/endPoints";
import * as Icon from 'react-bootstrap-icons';
import moment from 'moment/moment';
import _ from "underscore"
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';

import Images from '../../../../Assets/Images/Index';
import { useSelector } from 'react-redux';
import LineChart from './Charts/LineChart';
import ColumnChart from './Charts/ColumnChart';
import LineChartSingle from './Charts/LineChartSingle';
import PieChart from './Charts/PieChart';
import MultiAxisChart from './Charts/MultiAxis';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const filter1 = [
  { name: "Filter", value: "" },
  { name: "Last 6 Months", value: "last6Months " },
  { name: "Last 12 Months", value: "last12Months" },
  { name: "Last 18 Months", value: "last18Months" },
  { name: "Last 24 months", value: "last24months" },
]
const filter2 = [
  { name: "Filter", value: "" },
  { name: "Last 30 days", value: "last30days " },
  { name: "Last 60 days", value: "last60days" },
  { name: "Last 90 days", value: "last90days" },
  { name: "Last 120 days", value: "last120days" },
  { name: "Last 180 days", value: "last180days" },
  { name: "Last 365 days", value: "last365days" },
]
const salesDate = [
  { name: "7 Days", value: "7days" },
  { name: "14 Days", value: "14days" },
  { name: "30 Days", value: "30days" },
]
const userData = []

export default function RevenuePerformance() {
  const [chartData, setchartData] = useState({})
  const [loading, setloading] = useState(true)

  const [dates, setDates] = useState({
    "HIGHVALUECUSTOMER": {
      startDate: null,
      endDate: null
    },
    "RELOADUSERS": {
      startDate: null,
      endDate: null
    }

  });

  const getData = (url, param) => {
    Api.GetApi(`${endPoints[url]}${param}`).then(res => {
      console.log(`res123 ${url}`, res)
      setTimeout(() => {
        setloading(false)
      }, 1000);
      if (res.error === true) {
        Api.Alert(res.response.data.error, "error")
      } else {
        let chart_data = {}
        if (res.data.data) {
          if (url == "MONTHLYREVENUE" && res.data.data.length) {
            let months = []
            let actual = []
            let projected = []
            res.data.data.map((data) => {
              // months.push(`${data.month < 10 ? "0" + data.month : data.month}-${data.year}`)
              months.push(data.date)
              actual.push(data.actual_revenue.toFixed(2))
              projected.push(data.projected_revenue.toFixed(2))
            })
            chart_data["month"] = months
            chart_data["actual"] = actual
            chart_data["projected"] = projected
          }
          if (url == "REVENUEBREAKDOWN") {
            let labels = []
            let values = []
            if (res.data.data.total_map) {
              Object.entries(res.data.data.total_map).forEach(([key, val]) => {
                labels.push(key)
                values.push(val)
              })
              chart_data["labels"] = labels
              chart_data["values"] = values
            }
          }
        }
        setchartData((prevData) => ({
          ...prevData,
          [url]: {
            ...prevData[url],
            data: res.data.data,
            chart: chart_data,
          },
        }));
        // setloading(false)
      }
    })
  }

  const handleFilter = (e, key) => {
    if (e.target.value) {
      getData(e.target.name, `?${key}=${e.target.value}`)
    } else {
      getData(e.target.name, "")
    }
  }
  const onDateChange = (dates, name) => {
    const [start, end] = dates;
    setDates((prevDates) => ({
      ...prevDates,
      [name]: {
        ...prevDates[name],
        startDate: start,
        endDate: end
      }
    }));
    if (start && end) {
      getData(name, `?from=${moment(start).format("YYYY-MM-DD")}&to=${moment(end).format("YYYY-MM-DD")}`)
    } else {
      if (!start) {
        getData(name, "")
      }
    }
  };

  useEffect(() => {
    const arr = [
      "MONTHLYREVENUE",
      "REVENUEBREAKDOWN",
      "LIFETIMEVALUE",
      "HIGHVALUECUSTOMER",
      "RELOADUSERS"
    ]
    arr.map((data) => {
      getData(data, '')
    })
  }, [])

  console.log("chartData", chartData)

  return (
    <>

      {loading ?
        <div className="loader-line"></div> :
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Revenue & business performance</h1>
          </div>
          <section className='section dashboard '>
            <div className='row'>
              <div className="col-xxl-7 col-xl-6">
                <div className="card info-card sales-card mb-4">
                  <div className="card-body dashCard">
                    <div className="d-flex align-items-center justify-content-between mb-1">
                      <h5 className="card-title pb-2 m-md-0">Monthly Revenue Growth & Projection</h5>
                      <div className="d-flex align-items-center justify-content-end">
                        <select className="ms-2 form-select dashFilterSelect form-md" aria-label="Default select example" name="MONTHLYREVENUE" onChange={(e) => handleFilter(e, "filter")}>
                          {filter1.map((data, indx) => {
                            return <option value={data.value} key={indx}>{data.name}</option>
                          })}
                        </select>
                      </div>
                    </div>
                    {chartData && chartData.MONTHLYREVENUE && chartData.MONTHLYREVENUE.chart ?
                      <div className="dashBarChartHolder" style={{
                        height: "220px",
                      }}>
                        <LineChart
                          data={chartData.MONTHLYREVENUE.chart}
                          labels={chartData.MONTHLYREVENUE.chart.month}
                          chart={[
                            {
                              label: "Actual revenue",
                              data: chartData.MONTHLYREVENUE.chart.actual,
                              borderColor: "green",
                              backgroundColor: "rgba(0, 255, 0, 0.2)",
                              borderWidth: 2,
                              fill: false,
                              tension: 0.1,
                              pointRadius: 0
                            },
                            {
                              label: "Projected revenue",
                              data: chartData.MONTHLYREVENUE.chart.projected,
                              borderColor: "red",
                              backgroundColor: "rgba(255, 0, 0, 0.2)",
                              borderWidth: 2,
                              fill: false,
                              tension: 0.1,
                              borderDash: [5, 5],
                              pointRadius: 0
                            },
                          ]}
                        />
                      </div>
                      : null
                    }
                  </div>
                </div>
              </div>
              <div className="col-xxl-5 col-xl-6">
                <div className="card info-card sales-card mb-4">
                  <div className="card-body dashCard">
                    <div className="d-flex align-items-center justify-content-between mb-1">
                      <h5 className="card-title pb-2 m-md-0">Customer Lifetime Value(LTV)</h5>
                      <div className="d-flex align-items-center justify-content-end">
                        <select className="ms-2 form-select dashFilterSelect form-md" aria-label="Default select example" name="LIFETIMEVALUE" onChange={(e) => handleFilter(e, "filter")}>
                          {filter2.map((data, indx) => {
                            return <option value={data.value} key={indx}>{data.name}</option>
                          })}
                        </select>
                      </div>
                    </div>
                    {chartData && chartData.LIFETIMEVALUE && chartData.LIFETIMEVALUE.data ?
                      <h3 className='mb-0'>
                        <CurrencyFormat value={chartData.LIFETIMEVALUE.data.ltv.toFixed(2) || 0} displayType={'text'} thousandSeparator={true} prefix={''} />
                      </h3>
                      : null
                    }
                    <hr style={{ margin: "10px 0" }} />
                    <div className='row align-items-end'>
                      {chartData && chartData.LIFETIMEVALUE && chartData.LIFETIMEVALUE.data ?
                        <div className="col-md-6">
                          <div className="d-flex gap-1 gap-md-2">
                            <div className="card-icon rounded-circle ">
                              <Icon.CurrencyDollar className="me-0" />
                              {/* <img src={Images.moneyBag} /> */}
                              <small><strong>MYR</strong></small>
                            </div>
                            <div className="ps-1">
                              <div className="mb-3">
                                <h4 className='mb-0'>
                                  <CurrencyFormat value={parseFloat(chartData.LIFETIMEVALUE.data.total_revenue.toFixed(2) || "0")} displayType={'text'} thousandSeparator={true} prefix={'RM'} />
                                </h4>
                                <h5 className="card-title pb-0 font-med">Total revenue</h5>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex gap-1 gap-md-2">
                            <div className="card-icon rounded-circle ">
                              <Icon.Person className="me-0" />
                            </div>
                            <div className="ps-1">
                              <div className="mb-3">
                                <h4 className='mb-0'>
                                  <CurrencyFormat value={chartData.LIFETIMEVALUE.data.active_user_count || 0} displayType={'text'} thousandSeparator={true} prefix={''} />
                                </h4>
                                <h5 className="card-title pb-0 font-med">Active users</h5>
                              </div>
                            </div>
                          </div>
                          {/* <div className="d-flex gap-1 gap-md-2">
                            <div className="card-icon rounded-circle ">
                              <Icon.Person className="me-0" />
                            </div>
                            <div className="ps-1">
                              <div className="mb-0">
                                <h3 className='mb-0'>
                                  <CurrencyFormat value={chartData.LIFETIMEVALUE.data.ltv.toFixed(2) || 0} displayType={'text'} thousandSeparator={true} prefix={''} />
                                </h3>
                                <h5 className="card-title pb-0 font-med">LTV</h5>
                              </div>
                            </div>
                          </div> */}
                        </div>
                        : null
                      }

                      <div className="col-md-6">
                        {chartData && chartData.LIFETIMEVALUE && chartData.LIFETIMEVALUE.data && chartData.LIFETIMEVALUE.data.sales.length ?
                          <div className="dashBarChartHolder" style={{
                            height: "150px",
                          }}>
                            <LineChartSingle
                              data={chartData.LIFETIMEVALUE.data.sales.map((data) => data.amount.toFixed(2) || 0) || []}
                              label={chartData.LIFETIMEVALUE.data.sales.map((_) => ` `)}
                              labels={["Amount (RM)"]}
                              tooltip={chartData.LIFETIMEVALUE.data.sales.map((data) => `${data._id}, Sessions: ${data.count}`) || []}
                            />
                          </div>
                          : null
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {chartData.HIGHVALUECUSTOMER && chartData.HIGHVALUECUSTOMER.data && chartData.HIGHVALUECUSTOMER.data.length > 0 ?
              <div className="card info-card sales-card mb-4">
                <div className="card-body dashCard">
                  <div className="d-flex align-items-center justify-content-between mb-1">
                    <h5 className="card-title pb-2 m-md-0">Top 50 High-Value Customers (HVCs)</h5>
                    <div className='date-range-outer'>
                      <DatePicker
                        onChange={(e) => onDateChange(e, "HIGHVALUECUSTOMER")}
                        selected={dates.HIGHVALUECUSTOMER.startDate}
                        startDate={dates.HIGHVALUECUSTOMER.startDate}
                        endDate={dates.HIGHVALUECUSTOMER.endDate}
                        selectsRange
                        maxDate={new Date()}
                        placeholderText="Select date range"
                        className="form-control datepicker"
                        isClearable={true}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-5'>
                      <div className="chart-table table-responsive">
                        <table className="table dataTable copartnerTable">
                          <thead>
                            <tr>
                              <th scope="col">Name</th>
                              <th scope="col">Email/phone</th>
                              <th scope="col">Total spent</th>
                              <th scope="col">Sessions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {chartData.HIGHVALUECUSTOMER.data.map((data, index) => {
                              return (
                                <tr key={index} >
                                  <td>
                                    <strong>{data?.first_name || ""} {data?.last_name || ""}</strong><br />
                                    <small style={{ fontSize: "70%" }}>{data.user_id}</small>
                                  </td>
                                  <td>
                                    {data?.email || ""}<br />
                                    {data?.Phone_Number || ""}
                                  </td>
                                  <td>
                                    <CurrencyFormat value={parseFloat(data?.total_amount_spent).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} />
                                  </td>
                                  <td>{data?.session_count || ""}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className='col-lg-7'>
                      {chartData && chartData.HIGHVALUECUSTOMER && chartData.HIGHVALUECUSTOMER.data.length ?
                        <div className="dashBarChartHolder" style={{
                          height: "auto",
                        }}>
                          <ColumnChart
                            data={[
                              {
                                label: "Total Amount Spent (RM)",
                                data: chartData.HIGHVALUECUSTOMER.data.map((c) => c.total_amount_spent),
                                backgroundColor: "rgba(75, 192, 192, 1)", // Green bars
                                borderRadius: 0,
                              },
                              {
                                label: "Session Count",
                                data: chartData.HIGHVALUECUSTOMER.data.map((c) => c.session_count),
                                backgroundColor: "rgba(255, 99, 132, 1)", // Red bars
                                borderRadius: 0,
                              },
                            ]}
                            label={chartData.HIGHVALUECUSTOMER.data.map((_,) => ` `)}
                            // tooltip={chartData.HIGHVALUECUSTOMER.data.map((value) => `User ID: ${value.user_id}`)}
                            tooltip={{
                              callbacks: {
                                title: (tooltipItems) => {
                                  const index = tooltipItems[0].dataIndex;
                                  return `User ID: ${chartData.HIGHVALUECUSTOMER.data[index].user_id}`;
                                }
                              },
                            }}
                          />
                        </div>
                        : null
                      }
                    </div>
                  </div>
                </div>
              </div>
              : null
            }
            {chartData.RELOADUSERS && chartData.RELOADUSERS.data && chartData.RELOADUSERS.data.length > 0 ?
              <div className="card info-card sales-card mb-4">
                <div className="card-body dashCard">
                  <div className="d-flex align-items-center justify-content-between mb-1">
                    <h5 className="card-title pb-2 m-md-0">Top 50 Most Frequent Reload Users</h5>
                    <div className='date-range-outer'>
                      <DatePicker
                        onChange={(e) => onDateChange(e, "RELOADUSERS")}
                        selected={dates.RELOADUSERS.startDate}
                        startDate={dates.RELOADUSERS.startDate}
                        endDate={dates.RELOADUSERS.endDate}
                        selectsRange
                        maxDate={new Date()}
                        placeholderText="Select date range"
                        className="form-control datepicker"
                        isClearable={true}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-5'>
                      <div className="chart-table table-responsive">
                        <table className="table dataTable copartnerTable">
                          <thead>
                            <tr>
                              <th scope="col">User</th>
                              <th scope="col">Total amount</th>
                              <th scope="col">Total reloads</th>
                              <th scope="col">Last reload</th>
                            </tr>
                          </thead>
                          <tbody>
                            {chartData.RELOADUSERS.data.map((data, index) => {
                              return (
                                <tr key={index} >
                                  <td>
                                    <strong>{data?.name || ""}</strong><br />
                                    <small style={{ fontSize: "70%" }}>{data.userId}</small>
                                  </td>
                                  <td>
                                    <CurrencyFormat value={parseFloat(data?.totalAmount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} />
                                  </td>
                                  <td>
                                    {data?.totalReloads || 0}
                                  </td>
                                  <td>
                                    {moment(data?.lastTopup).format("ll")}
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className='col-lg-7'>
                      {chartData && chartData.RELOADUSERS && chartData.RELOADUSERS.data.length ?
                        <div className="dashBarChartHolder" style={{
                          height: "400px",
                        }}>
                          <LineChartSingle
                            data={chartData.RELOADUSERS.data.map((c) => c.totalReloads || 0) || []}
                            label={chartData.RELOADUSERS.data.map((_) => ` `)}
                            labels={["Reload Users"]}
                            tooltip={chartData.RELOADUSERS.data.map((data) => `${data.userId || ""}\n Email: ${data.email || ""}`) || []}
                          />
                        </div>
                        : null
                      }
                    </div>
                  </div>
                </div>
              </div>
              : null
            }


            <div className="card info-card sales-card mb-4">
              <div className="card-body dashCard">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <h5 className="card-title pb-2 m-md-0">Revenue Breakdown by Charger Type (AC/DC)</h5>
                  <div className="d-flex align-items-center justify-content-end">
                    <select className="ms-2 form-select dashFilterSelect form-md" aria-label="Default select example" name="REVENUEBREAKDOWN" onChange={(e) => handleFilter(e, "filter")}>
                      {filter2.map((data, indx) => {
                        return <option value={data.value} key={indx}>{data.name}</option>
                      })}
                    </select>
                  </div>
                </div>
                <div className='row justify-content-between'>
                  <div className="col-lg-4">
                    {chartData && chartData.REVENUEBREAKDOWN && chartData.REVENUEBREAKDOWN.chart && chartData.REVENUEBREAKDOWN.chart.values && chartData.REVENUEBREAKDOWN.chart.values.length ?
                      <div className="dashBarChartHolder" style={{
                        height: "auto",
                      }}>
                        <PieChart
                          data={chartData.REVENUEBREAKDOWN.chart.values.map((value) => value.total_spend.toFixed(2))}
                          tooltip={chartData.REVENUEBREAKDOWN.chart.values.map((value) => `Sessions: ${value.total_count}\n Revenue (RM): ${value.total_spend.toFixed(2)}`)}
                          labels={chartData.REVENUEBREAKDOWN.chart.labels}
                        />
                      </div>
                      : null
                    }
                  </div>
                  <div className="col-lg-7">
                    {chartData && chartData.REVENUEBREAKDOWN && chartData.REVENUEBREAKDOWN.data && chartData.REVENUEBREAKDOWN.data.location && chartData.REVENUEBREAKDOWN.data.location.length ?
                      <div className="dashBarChartHolder" style={{
                        height: "auto",
                      }}>
                        {/* <ColumnChart
                          data1={chartData.REVENUEBREAKDOWN.data.location.map((c) => c.total_revenue)}
                          data2={chartData.REVENUEBREAKDOWN.data.location.map((c) => c.total_session)}
                          // data3={chartData.REVENUEBREAKDOWN.data.location.map((c) => c.charger.filter((d)=> d._id === "AC").length && c.charger.filter((d)=> d._id === "AC"))}                          
                          labels={["Total Revenue (RM)", "Total Session"]}
                          label={chartData.REVENUEBREAKDOWN.data.location.map((_,) => ` `)}
                          tooltip={chartData.REVENUEBREAKDOWN.data.location.map((value) => `Location: ${value.operator_name}`)}
                          /> */}
                        <MultiAxisChart
                          data1={chartData.REVENUEBREAKDOWN.data.location.map((c) => c.total_revenue)}
                          data2={chartData.REVENUEBREAKDOWN.data.location.map((c) => c.total_session)}
                          labels={["Total Revenue (RM)", "Total Session"]}
                          label={chartData.REVENUEBREAKDOWN.data.location.map((value) => ` `)}
                          tooltip={chartData.REVENUEBREAKDOWN.data.location.map((value) => `Location: ${value.operator_name}`)}
                        />
                      </div>
                      : null
                    }
                  </div>
                </div>
              </div>
            </div>

          </section>

        </main>
      }

    </>
  )
}
